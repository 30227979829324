import 'ol/ol.css';
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import TileLayer from 'ol/layer/Tile.js';
import OSM from 'ol/source/OSM.js';
import TileWMS from 'ol/source/TileWMS.js';
import {fromLonLat} from 'ol/proj';

const brussels = [4.35, 50.85];
const brusselsWebMercator = fromLonLat(brussels);

//    new TileLayer({
//      source: new OSM()
//    })

var layers = [
  new TileLayer({
    source: new TileWMS({
      url: 'https://geoserver.wow-region.com/wms',
      params: {'LAYERS': 'wow-routes:wow-routes', 'TILED': true},
      serverType: 'geoserver',
      transition: 0
    })
  })
];

var map = new Map({
  layers: layers,
  target: 'map',
  view: new View({
    center: brusselsWebMercator,
    maxZoom: 20,
    zoom: 12
  })
});
